import React from 'react';
import {PolIcon} from "components/PolIcon/policon";

export default function DebugBarPerformance({performance, debugUrl}) {

    const status = performance.sub_requests.length <= 2 ? 'success' : 'error';

    return (
        <>
            <div className="debug-bar__item">
                <PolIcon icon="clock"/>
                <span title="Performance">{performance.total}ms</span>

                <div className="debug-bar__item-hover">
                    <p className="debug-bar__item-hover-row">
                        <span>cache get</span> <b>{performance.cache.get.time}ms</b>
                    </p>
                    <p className="debug-bar__item-hover-row">
                        <span>cache invalidate</span> <b>{performance.cache.invalidate.time}ms</b>
                    </p>
                    <p className="debug-bar__item-hover-row">
                        <span>cache invalidate tags</span> <b>{performance.cache.invalidateTags.time}ms</b>
                    </p>
                    <p className="debug-bar__item-hover-row">
                        <span>cache set</span> <b>{performance.cache.set.time}ms</b>
                    </p>
                    <hr/>
                    <p className="debug-bar__item-hover-row">
                        <span>doctrine</span> <b>{performance.doctrine.time}ms</b>
                    </p>
                    <hr/>
                    <p className="debug-bar__item-hover-row">
                        <span>symfony</span> <b>{performance.symfony}ms</b>
                    </p>
                </div>
            </div>
            <a href={`${debugUrl}?panel=db`}
               target="_blank"
               rel="noopener noreferrer"
               className="debug-bar__item"
               title="Queries">
                <PolIcon icon="database"/>
                <span>{performance.doctrine.queries}</span>
            </a>

            <div className="debug-bar__item uri">
                <PolIcon icon="subrequests"/>
                <a href={`${debugUrl}?panel=http_client`}
                   target="_blank"
                   rel="noopener noreferrer"
                   title="Sub requests">
                    <span className={`pol-badge pol-badge-${status}`}>{performance.sub_requests.length}</span>

                </a>


                <div className="debug-bar__item-hover">
                    {performance.sub_requests.map(subRequestRow)}
                </div>
            </div>
        </>
    )
}

function subRequestRow(subRequest) {
    const url = new URL(subRequest.request.url);

    return (
        <>
            <p className="debug-bar__item-hover-row">
                <span>{subRequest.performance.total}ms</span><span>{subRequest.request.method} {url.host}{url.pathname}</span>
            </p>
        </>
    );
}
